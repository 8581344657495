import { TaskFileUploadType } from "pages/project/common/taskBase.typing";
import { OfferStatus } from "pages/project/offer/offer.typing";
import { RequestStatus } from "pages/project/request/request.typing";
import { TaskStatus } from "pages/project/task/task.typing";

export const USER_TYPES: Record<UserType, UserTypeCode> = {
  supplier: "S",
  customer: "C",
};

type UserType = "supplier" | "customer";
export type UserTypeCode = "C" | "S";

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  userType: UserTypeCode;
}

export interface ZipFullDownloadDtoResponse {
  estimatedFileSize: number;
  guid: string; // not used for now, could be used as a fail-safe when the hub doesn't respond after some time
}

export interface OfferDtoResponse {
  offers: OfferDto[];
}

export interface ValidationRequestDtoListResponse {
  requests: ValidationExecutionRequestDto[];
}

export interface RejectionReasonDto {
  id: number;
  description: string;
}

export interface ValidationRequestRejectionReasonDto {
  id: number;
  description: string;
}

export interface TaskBaseDto {
  id: number;
  projectName: string;
  taskCode: string;
  contactEmail: string | null;
  contactName: string | null;
  sourceLanguageCode: string;
  sourceLanguage: string;
  targetLanguageCode: string;
  targetLanguage: string;
  startDate: Date | null;
  dueDate: Date | null;
  quantity: number | null;
  unit: { description: string; decimals: number } | null;
  price: number | null;
  currency: string | null;
  taskType: string;
  customerName: string;
  secondQuantity: number | null;
  secondUnit: { description: string; decimals: number } | null;
  catToolId: number;
  qaFormRequirement: number; //    None = 1, Mandatory = 2, Optional = 3,
  supplier: Supplier;
  workflowExecutionType: WorkflowExecutionType;
  usePartnerPortalUploadWithAntivirus: boolean;
}

export enum WorkflowExecutionType {
  None,
  CAT,
  Manual,
}

export interface TaskDtoResponse {
  acceptedTasks: TaskDto[];
}

export interface OfferDto extends TaskBaseDto {
  taskId: number;
  status: OfferStatus;
}

export interface ValidationExecutionRequestDto extends TaskBaseDto {
  taskId: number;
  status: RequestStatus;
  acceptedByAnother: boolean;
}

export interface TaskDto extends TaskBaseDto {
  status: TaskStatus;
}

export interface TaskBaseFileDtoResponse {
  deliveredFiles: TaskBaseFileDto[];
}

export interface MemSourceProjectLinksDtoResponse {
  memsourceProjectLinks: MemSourceProjectLinkDto[];
}

export interface Translate5ProjectLinksDtoResponse {
  catToolProjectLinks: CatToolProjectLinkDto[];
}

export interface TaskBaseFileDto {
  fileName: string;
  creationTime: Date;
  fileType: TaskFileUploadType;
}

export interface MemSourceProjectLinkDto {
  catProjectId: string;
  catProjectLink: string;
  catProjectName: string;
}

export interface CatToolProjectLinkDto {
  projectId: string;
  projectLink: string;
  projectName: string;
}

export interface XtmProjectsDtoResponse {
  xtmProjects: XtmProjectDto[];
}

export interface XtmProjectDto {
  xtmProjectId: number;
  xtmProjectName: string;
  anonymousJobsLink: string;
}

export interface XtmJobsLinkDto {
  xtmProjectId: number;
  url: string;
}

export type CatStepsProgressDto = {
  fileName: string;
  segmentsDone: number;
  totalSegments: number;
};
export type CatTotalStepsProgressDto = {
  segmentsDone: number;
  totalSegments: number;
};

export interface CatStepsDto {
  stepsProgress: CatStepsProgressDto[];
  totalStepsProgress: CatTotalStepsProgressDto;
}

export interface QAFormCriteriaDto {
  title: string | null;
  criteria: Array<{ key: number; value: string }>;
}

export interface QAFormDto {
  answers: Array<{ key: number; value: string }>;
  criteriaGroups: Array<QAFormCriteriaDto>;
}

export interface IdName<T extends string | number = number> {
  id: T | null;
  name: string;
}

export interface Supplier extends IdName {
  supplierContact: IdName;
  supplierInternallyManaged: boolean;
  tasksVisibleForAllContacts: boolean;
}

export interface PreInvoiceDto {
  id: number;
  documentNumber: number;
  currencyCode: string;
  issuedDate: Date;
  reIssuedDate: Date | null;
  entityName: string;
  entityId: number;
  branchName: string;
  branchId: number;
  price: number;
}

export interface PreInvoiceTaskDto extends TaskDto {
  supplierPreInvoiceId: number;
  endTime: Date | null;
}

export interface PreInvoiceDetailDto {
  invoice: PreInvoiceDto;
  tasks: PreInvoiceTaskDto[];
}

export interface TaskInstructionDto {
  instructionId: number;
  description: string;
  title: string;
  formattedText?: string | null;
}

export interface MemsourceTaskJobsCompletion {
  taskId: number;
  completed: boolean;
}
